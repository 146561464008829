footer#footer {
  .footer-img {
    background-size: cover;
    background-position: center;
    position: relative;
    height: 300px;

    .container {
      color: #fff;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: 100%;
      grid-gap: 40px;

      > div {
        position: relative;
        padding: 0 0 20px;
        align-self: flex-end;

        p {
          font-size: 20px;
        }
      }
    }
  }

  .main,
  .copy {
    .container {
      display: grid;
      grid-gap: 15px;

      @screen sm {
        grid-template-columns: repeat(3, 1fr);
      }

      @screen md {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  .main {
    padding: 60px 0;
    color: #fff;
    font-size: 15px;

    .logo {
      img {
        max-width: 100%;
        width: 180px;
      }
    }

    .menu {
      > :first-child {
        font-size: 16px;
      }

      a {
        display: block;
        color: #fff;

        &:hover,
        &:active,
        &:focus {
          color: #f5f5f5;
        }
      }
    }
  }

  .copy {
    background-color: #333;
    padding: 20px 0;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    display: flex;

    a {
      font-size: 12px;
      color: #f5f5f5;

      &:hover,
      &:active,
      &:focus {
        color: #fff;
      }
    }

    /*@include respond-to(xs) {
      .container {
        flex-direction: column;
      }

      a {
        margin-bottom: 10px;
      }
    }*/
  }
}
